// Use this to write your custom SCSS
// set vertical images width for home image slider (calculation based on _carousel.scss)
@media (min-width: 1024px) {
    .swiper-auto .swiper-vertical {
        width: 376px;
    }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
    .swiper-auto .swiper-vertical {
      width: 263px;
    }
}
@media (max-width: 767px) {
    .swiper-auto .swiper-vertical {
        width: 44%;
        /*width: 149px;*/

    }
}